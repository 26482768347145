import React from 'react'
import SbEditable from 'storyblok-react'
import StoryblokClient from 'storyblok-js-client'
import isNode from 'is-node'

import * as styles from './editor.module.scss'
import Components from '../../storyblok/Components'

const resolve_relations = []

const getQueryParam = (param: string): string => {
  if (!isNode) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param) as string
  } else {
    return ''
  }
}

const getParam = function (val: any) {
  let result = ''
  let tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

// loadStory is triggered on init and on input change in storyblok
// if the story uses references in storyblok, they will be fetched on every loadStory
//
// if the story uses some kind of post data where we fetch all of a type, that data will be
// fetched on init with fetchPostsBySlug and added to extraContent

class StoryblokEntry extends React.Component {
  storyblok: any
  editMode = true

  constructor(props: any) {
    super(props)

    // TODO: Activate
    // this.validateUser()

    this.state = {
      story: null,
      extraContent: { globalProduct: {} },
      schedule: [],
    }
  }

  validateUser = () => {
    const validationString =
      getQueryParam('_storyblok_tk[space_id]') +
      ':' +
      getQueryParam('_skey') +
      ':' +
      getQueryParam('_storyblok_tk[timestamp]')
    const validationToken = crypto
      .createHash('sha1')
      .update(validationString)
      .digest('hex')
    if (
      getQueryParam('_storyblok_tk[token]') == validationToken &&
      parseInt(getQueryParam('_storyblok_tk[timestamp]')) >
        Math.floor(Date.now() / 1000) - 3600
    ) {
      // you're in the edit mode.
      this.editMode = true
    }
  }

  loadStoryblokBridge = (cb: any) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (this.editMode) {
      script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${getQueryParam(
        '_skey'
      )}`
      script.onload = cb
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  componentDidMount() {
    if (this.editMode) {
      if (document) {
        const cookieEl = document.getElementById('onetrust-consent-sdk')
        cookieEl?.classList.add('hide')
      }
      console.log('TOKEJ', getQueryParam('_skey'))

      this.storyblok = new StoryblokClient({
        accessToken: getQueryParam('_skey'),
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      })
      this.loadStoryblokBridge(() => {
        this.initStoryblokEvents()
      })
    }
  }

  async fetchPostsByComponent(component: string, sort_by = '') {
    let posts: any = []

    await this.storyblok
      .get('cdn/stories', {
        by_slugs: '*',
        sort_by: sort_by,
        per_page: 100,
        filter_query: {
          component: {
            in: component,
          },
        },
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          posts = response.data.stories
        }
      })

    return posts
  }

  async fetchSiblings(story: any) {
    let starts_with = story.full_slug
    let links: any = []
    if (!story.is_startpage) {
      starts_with = story.full_slug.replace(story.slug, '')
    }
    await this.storyblok
      .get('cdn/links/', {
        starts_with: starts_with,
      })
      .then((response: any) => {
        if (response && response.data && response.data.links) {
          links = Object.values(response.data.links).map((link: any) => ({
            slug: link.slug,
            name: link.name,
          }))
        }
      })
      .catch((error: any) => {
        console.log(error)
      })

    return links
  }

  async loadContentNav(story: any) {
    story.content.navItems = await this.fetchSiblings(story)
    this.setState({ story: story })
  }

  loadStory(init?: boolean) {
    let path = getParam('path')
    let locale = ''
    if (path.startsWith('en/')) {
      locale = 'en'
      path = path.replace('en/', '')
    }

    this.storyblok
      .get(`cdn/stories/${path}`, {
        version: 'draft',
        language: locale,
        resolve_relations: resolve_relations.join(','),
      })
      .then(async (response: any) => {
        const { data } = response

        if (data && data.story) {
          this.setState({
            story: data.story,
          })
        }
      })
      .catch((e: any) => {
        console.log('CATCH', e)
      })
  }
  initStoryblokEvents() {
    this.loadStory(true)

    const sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory()
    })

    sb.on('input', (payload: any) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )

        sb.resolveRelations(payload.story, resolve_relations, () => {
          const story = payload.story
          const preFetchedContent = this.state.story.content
          story.content = { ...preFetchedContent, ...story.content }
          this.setState({ story: story })
        })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    const { story, extraContent, schedule } = this.state

    if (story == null) {
      return <div />
    }

    let content = story.content
    content = {
      ...content,
      ...extraContent,
    }
    if (content.body) {
      content.body = content.body.map((section: any) => {
        if (section.component === 'schedule_section') {
          return {
            ...section,
            schedule,
          }
        } else {
          return section
        }
      })
    }

    return (
      <SbEditable content={content}>
        <div className={styles.editor}>
          {React.createElement(Components(story.content.component), {
            storyID: story.uuid,
            tags: story.tag_list,
            name: story.name,
            slug: story.slug,
            isStartPage: story.is_startpage,
            full_slug: story.full_slug,
            date: story.published_at,
            blok: content,
            preview: true,
            // footer: footer,
          })}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
